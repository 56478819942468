import api from '../conf/ruta-base'
import axios from 'axios'
import store from "../store";
import aggregateHeaders from './headers';
import router from "../router";
const clientHttp = axios.create({
    baseURL: api.api
})

clientHttp.interceptors.request.use(
    function (config) {
        config.headers = config.headers || {};
        aggregateHeaders(clientHttp);
        return config;

    },
    function (error) {
        return Promise.reject(error);
    }
);

clientHttp.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        if (
            (error.response.status === 401 || error.response.status === 403) &&
            originalRequest.url === "/auth/refresstoken"
        ) {
            sessionStorage.removeItem("token");
            router.push("login");
            return Promise.reject(error);
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            sessionStorage.token = sessionStorage.getItem("refress_token")

            return clientHttp
                .get("/auth/refresstoken")
                .then((res) => {
                    if (res.status === 200) {
                        sessionStorage.token = res.data.access_token;
                        clientHttp.defaults.headers = {
                            Authorization: "Bearer " + res.data.access_token,
                        };
                        return axios(originalRequest);
                    } else {
                        router.push("login");
                    }
                })
        }
        return Promise.reject(error);
    }
);

export default clientHttp;