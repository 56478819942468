let idRequest = null;
let requestsControl = [];
let disponibles = [];
const scannerOk = new Set();
const reqsOk = new Set();

export const assingRequerimientos = (id, requests, barcodeScaned) => {
    console.log("barcode1 Inicio", id);
    processRequests(id, requests);
    disponibles = barcodeScaned;
    if(disponibles.length > 0) processar();
    return requestsControl;
}

const assingProduct = (pfa, disponible) => {
    console.log("barcode5", pfa, disponible);
  if(!pfa?.quantityAssigned) pfa.quantityAssigned = 0;
  const necesary = pfa.quantity - pfa.quantityAssigned;
  if(disponible.quantity >= necesary){
    pfa.quantityAssigned += necesary
    disponible.quantity -= necesary
    reqsOk.add(pfa.id)
  } else {
    pfa.quantityAssigned += disponible.quantity
    disponible.quantity = 0
    scannerOk.add(disponible.id)
  }
}

const applyProduct = (productsForApply) => {
    console.log("barcode4", productsForApply, disponibles);
    disponibles.forEach(disponible => {
      if(!scannerOk.has(disponible.id) && productsForApply.productName == disponible.product){
        assingProduct(productsForApply, disponible)
      }
    });
}

const processar = () => {
    console.log("barcode30", requestsControl);
    requestsControl.forEach(product => {
        console.log("barcode3", product);
        if(!reqsOk.has(product.id)) {
            const assigned = product.quantityAssigned || 0;
            if(assigned < product.quantity) applyProduct(product);
        } else {
            console.log("barcode31", product);
        }
    });
}

const processRequests = (id, requests) => {
  if(idRequest !== id || requestsControl.length === 0) {
    requests.forEach(request => {
      request.productName = request.product.name;
      request.quantityAssigned = 0
    });
    requestsControl = requests
    idRequest = id;
  }
  console.log("barcode1", requestsControl);
  return requestsControl;
}
