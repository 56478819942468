import clientHttp from '../tools/clientHttpSilent'
import UserProfile from "../data";
const user = UserProfile.getUserData();
const URL = "/barcode";

export const createBarcodeProccess = async (activateBarcodeDto) => {
    return (await clientHttp.post(`${URL}`, activateBarcodeDto)).data
}

export const getBarcodeById = async (id) => {
    return (await clientHttp.get(`${URL}/${id}`)).data
}

export const getBarcodeProccess = async (code) => {
    return (await clientHttp.get(`${URL}/code/${code}`)).data
}

export const activateBarcodeProccess = async (code) => {
    return (await clientHttp.get(`${URL}/activate/${code}`)).data
}

export const getProductByName = async (name) => {
    const products =  (await clientHttp.get(`${URL}/get-product/${name}`)).data
    if(products.length > 0) return products[0];
    return null;
}

export const agregateProduct = async (product) => {
    return clientHttp.post(`${URL}/agregate`, product)
}