import swal from "sweetalert";
import { updateRequest } from '../dataApi'

export const generateDeliveries = async (request, ref) => {
    console.log("TestAlex",request, ref)
    const setFinal = await swal({text: "Se procede a finalizar exitosamente el Despacho, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
    
    if(!setFinal) return false

    await updateRequest({
        id: request,
        status: 'DESPACHADO',
        ref,
        delivery: true
    })

    return true
}

export const generateDispach = async (request, ref) => {
    const setFinal = await swal({text: "Se procede a finalizar exitosamente la Recepcion, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
    
    if(!setFinal) return false

    await updateRequest({
        id: request,
        status: 'RECEPCIONADO',
        ref,
        delivery: true
    })

    return true
}