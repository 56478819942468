<template>
    <div>
        <v-card flat>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-data-table
                        :headers="headers"
                        :items="itemsDetails"
                        :items-per-page=-1
                        class="elevation-1 custom-font"
                        :loading="load"
                        loading-text="Cargando... Espere por favor"
                        :search="search"
                        v-model="selected"
                        :show-select="autorize"
                    >
                        <template v-slot:item.quantity="{ item }">
                            <v-chip v-if="item.color" :color="item.color" text-color="white">{{ item.quantity }}</v-chip>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                    </v-data-table>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-alert shaped dense dark type="info" v-if="info !== ''">
                    {{ info }}
                </v-alert>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="preGeneratePurchaseOrder" v-if="user.level !== 'user' && generateOrderPurchase">
                    Generar Orden de Compra
                </v-btn>
                <v-btn color="blue darken-1" text @click="preProcessPurchaseOrder" v-if="user.level !== 'user' && generatePurchase">
                    Finalizar Orden de Compra
                </v-btn>
                <v-btn color="blue darken-1" text @click="preGenerateDeliveries" v-if="user.level !== 'user' && deliver">
                    Generar Despacho
                </v-btn>
                <v-btn color="blue darken-1" text @click="proccessDispach" v-if="user.level !== 'user' && dispatch">
                    Recepcionar
                </v-btn>
                <v-btn color="blue darken-1" text @click="autorizar" v-if="user.level !== 'user' && autorize">
                    Autorizar
                </v-btn>
                <v-btn color="blue darken-1" text @click="comments = true" v-if="user.level !== 'user' && autorize">
                    Rechazar
                </v-btn>
                <v-btn color="blue darken-1" text @click="calculate" v-if="user.level !== 'user' && calculateStock && purchase">
                    Procesar Envio
                </v-btn>
                <v-btn color="blue darken-1" text @click="calculatePdf">
                    Imprimir
                </v-btn>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cerrar
                </v-btn>

            </v-card-actions>
        </v-card>
        <div>
            <ReportDataPdfBasic
            v-if="dataReady"
                :name="titlePdf"
                :title="titlePdf"
                :elementsPerPag=10
                :headerAll=false
                :headers="headersToPdf"
                :items="itemsDetailsToPdf"
                :inf="hoy"
                :showSignature="showSignature"
                ref="pdfComponent"
            />
        </div>
        <div>
            <ReportEntregaPdf
            v-if="dataEntregaReady"
                :name="titlePdf"
                :title="titlePdf"
                :elementsPerPag=8
                :headerAll=false
                :headers="headersToPdf"
                :items="itemsDetailsToPdf"
                :inf="hoy"
                :showSignature="showSignature"
                ref="pdfEntregaComponent"
            />
        </div>

        <div>
            <v-dialog v-model="comments" max-width="1000px" :key="keyView" >
                <comments v-on="{ closeModal, saveComment }" />
            </v-dialog>

            <v-dialog v-model="errors" max-width="950px" :key="errorView" >
                <DataTableBasic :headers="headersErrors" :items="itemsDetailsErrors" 
                :title="titleErrors"
                v-on="{ closeModal }" />
            </v-dialog>

            <v-dialog v-model="qrSync" max-width="1000px" :key="qrView" >
                <DataTableBasicSync :headers="headers" :items="itemsDetails" 
                :title="titleDeliveries" :solicitud="ref" 
                v-on="{ closeModal, okProccessDeliveries }" />
            </v-dialog>
        </div>
    </div>
</template>
<script>
import { getRequestById, updateRequest, getStockByProduct } from '../dataApi'
import UserProfile from "../data";
import ReportDataPdfBasic from "./report-data-basic-pdf.vue";
import ReportEntregaPdf from "./report-entrega-pdf.vue";
import comments from "./comments.vue"
import DataTableBasic from "./DataTableBasic.vue"
import DataTableBasicSync from './DataTableBasicSync.vue';
import { format } from "@formkit/tempo"
import swal from "sweetalert";
import { setAutorize, generatePurchaseOrder, processPurchaseOrder, generateDeliveries, generateDispach } from "../proccess";
export default {
    components: {
        ReportDataPdfBasic,
        ReportEntregaPdf,
        comments,
        DataTableBasic,
        DataTableBasicSync
    },
    props: {
        request: {
            type: Number,
            required: true
        },
        quoteRequest: {
            type: Boolean,
            required: false
        },
        purchaseOrder: {
            type: Boolean,
            required: false
        },
        purchase: {
            type: Boolean,
            required: false
        },
        statusRequest: {
            type: String,
            default: '',
            required: false
        }
    },
    name: 'requestView',
    data: () => ({
        user: UserProfile.getUserData(),
        itemsDetails: [],
        dataReady: false,
        dataEntregaReady: false,
        //headers: [],
        info: "",
        status: "",
        ref: "",
        load: true,
        search: "",
        comments: false,
        errors: false,
        qrSync: false,
        keyView: 0,
        errorView: 100,
        qrView: 200,
        selected: [],
        headersToPdf: [],
        itemsDetailsToPdf: [],
        titlePdf: "",
        headersErrors: [],
        itemsDetailsErrors: [],
        titleErrors: "",
        titleDeliveries: "Confirmar Entrega",
        showSignature: false,
    }),

    methods: {
        async createdValuesItems(){
            const { data } = await getRequestById(this.request)
            this.itemsDetails = data.items.map(item => ({
                ...item,
                employeeName: item.employee.firstName + ' ' + item.employee.fatherName
            }))
            this.load = false
            this.info = `Solicitante: ${data.user.fullName} en ${format(data.requestDate,'DD-MM-YYYY H:mm:ss', 'es')}`
            this.status = data.status
            this.ref = data.ref
        },

        cancelStock(){
            console.log("Entramos al close Modal")
            this.$emit("closeModal")
        },

        async calculatePdf(){
            console.log("calculatePdf",this.quoteRequest, this.deliver, this.purchaseOrder, this.statusRequest)
            if(this.statusRequest == 'RECEPCIONADO') {
                this.dataEntregaReady = true
                this.dataReady = false
            } else {
                this.dataEntregaReady = false
                this.dataReady = true
            }
            this.showSignature = false
            this.headersToPdf = [
                { text: 'Rut', value: 'employee.rut' },
                { text: 'Nombre', value: 'employee.firstName' },
                { text: 'Centro de Costo', value: 'employee.costCenter' },
                { text: 'Posicion', value: 'employee.position' },
                { text: 'Producto', value: 'product.description' },
                { text: 'Cantidad', value: 'quantity' },
            ]
            if(this.statusRequest === 'SOLICITADO'){
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'category.name' })
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Solicitud: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'AUTORIZADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Solicitud-Autorizada: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'EN PROCESO DE COMPRA'){
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'product.name' })
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Orden de Compra: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'RECIBIDO'){
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'product.name' })
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Por Entregar: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'ENTREGADO' || this.statusRequest === 'DESPACHADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Entregado: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'RECHAZADO'){
                this.itemsDetailsToPdf = this.itemsDetails
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'category.name' })
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Rechazado: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfComponent.generatePdf()
                }, 500)
            }
            if(this.statusRequest === 'RECEPCIONADO'){
                this.showSignature = true
                this.itemsDetailsToPdf = this.itemsDetails
                this.headersToPdf.splice(4, 1, { text: 'Producto', value: 'category.name' })
                console.log("this.itemsDetailsToPdf", this.itemsDetailsToPdf)
                this.titlePdf = `Formulario de Entrega de Uniformes: ${this.ref}`
                 setTimeout(() => {
                    this.$refs.pdfEntregaComponent.preGeneratePdf()
                }, 500)
            }
        },

        calculateTitlePdfToProvider(items = null){
            if(!items) return `Orden de Compra: ${this.ref}`;
            const supplier = items[0].product.supplier;
            return this.purchaseOrder ? `Orden de Compra: ${this.ref} - ${supplier.name}` : `Solicitud de Cotizacion: ${this.ref} - ${supplier.name}`
        },
        //TODO: trasladar logica a proccess
        async saveComment(comment){
            swal({text: "Se procede a RECHAZAR la solicitud, ¿Desea continuar?", icon: "info", buttons: ["Cancelar", "Continuar"],})
            .then(async() => {
                await updateRequest({
                    id: this.request,
                    status: 'RECHAZADO',
                    observation: comment
                })
                this.cancelStock()
            })
        },

        save(){
            console.log("save")
            this.keyView++
            this.errorView++
            this.qrView++
            this.comments = false
        },

        closeModal(){
            console.log("closeModal")
            this.keyView++
            this.errorView++
            this.qrView++
            this.comments = false
            this.errors = false
            this.qrSync = false
        },

        async autorizar(){
            const processAutorize = await setAutorize(this.itemsDetails, this.selected, this.request, this.ref);
            if(processAutorize.code === 200 && processAutorize.detail) {
                this.cancelStock()
                swal({text: processAutorize.detail, icon: "success", buttons: ["Aceptar"],})
                this.cancelStock();
            }
            if(processAutorize.code === 400 && processAutorize.detail){
                this.headersErrors = processAutorize.data.headers
                this.itemsDetailsErrors = processAutorize.data.items
                this.titleErrors = `${processAutorize.detail} - ${this.ref}`
                this.errors = true
                this.errorView++
            }
            console.log("processAutorize", processAutorize)
        },
        async preGeneratePurchaseOrder(){
            const generatePurchase = await generatePurchaseOrder(this.request)
            if(generatePurchase) this.cancelStock()
        },

        async preProcessPurchaseOrder(){
            const processPurchase = await processPurchaseOrder(this.request)
            if(processPurchase) this.cancelStock()
        },

        async preGenerateDeliveries(){
            console.log("preGenerateDeliveries", this.ref)
            this.qrSync = true
        },

        async proccessDispach(){
            const okDispach = await generateDispach(this.request, this.ref)
            if(okDispach) this.cancelStock()
        },

        async okProccessDeliveries(){
            const generateDeliver = await generateDeliveries(this.request, this.ref)
            if(generateDeliver) this.cancelStock()
        },

        async calculate(){
            this.load = true
            const requestInventory = []
            this.itemsDetails.map(item => {
                const exist = requestInventory.findIndex(product => product.productId === item.productId)
                if(exist !== -1){
                    requestInventory[exist].quantity += item.quantity
                }else{
                    requestInventory.push({
                        productId: item.productId,
                        quantity: item.quantity
                    })
                }
            })
            
            const { data } = await getStockByProduct({productQuantities: requestInventory})
            for await (const stock of data.productQuantities) {
                this.itemsDetails.forEach(item => {
                    if(!item.proccess && stock.stock > 0 && item.productId === stock.productId){
                        item.proccess = true
                        if(stock.stock >= item.quantity){
                            item.stock = item.quantity;
                            stock.stock -= item.quantity
                            item.color = 'green'
                        }
                    }
                })
            }
            let redFlag = false
            this.itemsDetails.forEach(item => {
                if(!item.proccess){
                    item.color = 'red'
                    item.stock = 0
                    redFlag = true
                }
            })
            if(redFlag){
                swal({text: "Existen productos que no tienen stock suficiente, ¿Desea continuar?", icon: "warning", buttons: ["Cancelar", "Continuar"],})
            }
            this.load = false
        },

        getKeyCaseInsensitive(obj, key) {
            if(!key) return null
            return Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
        },

        classifyItemsBySupplier(items) {
            console.log("classifyItemsBySupplier", items)
             const groupedBySupplier = items.reduce((acc, item) => {
                const supplierId = item.product.supplier.id;

                if (!acc[supplierId]) {
                    acc[supplierId] = {
                        supplier: item.product.supplier,
                        items: []
                    };
                }

                acc[supplierId].items.push(item);
                return acc;
            }, {});
            const finallyItems = Object.values(groupedBySupplier).map(group => group.items);
            console.log("classifyItemsBySupplier finally", finallyItems)
            return finallyItems
        },

        classifyItemsByEmployee(items){
            const groupedByEmployee = items.reduce((acc, item) => {
                const employeeId = item.employee.id;
                if(!acc[employeeId]){
                    acc[employeeId] = {
                        employee: item.employee,
                        items: []
                    };
                }
                acc[employeeId].items.push(item);
                return acc;
            }, {});
            return Object.values(groupedByEmployee);
        },
    },

    mounted() {
        this.createdValuesItems();
    },

    computed: {
        hoy(){
            const date = new Date();
            return format(date,'DD-MM-YYYY H:mm:ss', 'es')
        },
        title(){
            return `${this.ref} (${this.statusRequest})`
        },
        headers(){
            const headers = [
            { text: 'Nombre', value: 'employeeName' },
            { text: 'Cargo', value: 'employee.position' },
            { text: 'Cantidad', value: 'quantity' },
            { text: 'Observacion', value: 'observation' },
            ]
            if(this.status === 'SOLICITADO'){
                headers.splice(3, 0, { text: 'Producto', value: 'category.name' })
                return headers
            } else {
                headers.splice(3, 0, { text: 'Producto', value: 'product.description' })
                return headers
            }
        },
        autorize(){
            return this.status === 'SOLICITADO'
        },
        calculateStock(){
            return this.status === 'AUTORIZADO' && (!this.quoteRequest && !this.purchaseOrder)
        },
        generateOrderPurchase(){
            console.log(this.status, this.quoteRequest, this.purchaseOrder)
            return this.status === 'AUTORIZADO' && this.quoteRequest && !this.purchaseOrder && !this.ref.includes('Sol')
        },
        generatePurchase(){
            return this.status === 'EN PROCESO DE COMPRA' && this.purchaseOrder && !this.purchase
        },
        dispatch(){
            return this.status === 'ENTREGADO' || this.status === 'DESPACHADO';
        },
        deliver(){
            const res = (this.status === 'RECIBIDO' || (this.status === 'AUTORIZADO' && (this.ref.includes('Sol') || this.ref.includes('Aut'))))
            return res
        },
    }
}
</script>