<template>
    <div>
        <v-card flat>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page=-1
                        class="elevation-1 custom-font"
                        loading-text="Cargando... Espere por favor"
                    >
                        <template v-slot:item.quantity="{ item }">
                            <v-chip v-if="item.color" :color="item.color" text-color="white">{{ item.quantity }}</v-chip>
                            <span v-else>{{ item.quantity }}</span>
                        </template>
                    </v-data-table>

                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cerrar
                </v-btn>

            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    name: 'dataTableBasic',
    data: () => ({
        
    }),

    methods: {
        cancelStock(){
            this.$emit("closeModal")
        },
    },

}
</script>